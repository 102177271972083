import { MediaSize } from "../types"

export const alphabet = "abcdefghijklmnopqrstuvwxyz"
export const alphabetUpperCase = alphabet.toUpperCase()

export const MEDIA_QUERIES: Record<MediaSize, string> = {
  XS: "(max-width: 480px)",
  SM: "(min-width: 481px) and (max-width: 767px)",
  MD: "(min-width: 768px) and (max-width: 991px)",
  LG: "(min-width: 992px) and (max-width: 1199px)",
  XL: "(min-width: 1200px)"
}

export { default as USStates } from "./USStates"
export * from "./action-types"
export * from "./rate-table"
export * from "./rates-toolbar"
export * from "./embed-names"
export * from "./reviews-providers"
export * from "./subscription-dashboard"
export * from "./global-modal"
export * from "./line-chart"
export * from "./elements-ids"

export const MEDIA_QUERIES_HOVER_POINTER = {
  NO: "(hover: none) and (pointer: coarse)",
  YES: "(hover: hover) and (pointer: fine)"
}

export const ONE_DAY_IN_MILLISECONDS = 86400000
