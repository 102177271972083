import { createContext } from "preact"
import { RatesFormContextProps } from "../../types"
import { useContext } from "preact/hooks"

export const RatesFormContext = createContext<
  RatesFormContextProps | undefined
>(undefined)

export const useRatesFormContext = (): RatesFormContextProps => {
  const context = useContext(RatesFormContext)
  if (!context) {
    throw new Error("useMyContext must be used within a MyContextProvider")
  }
  return context
}
