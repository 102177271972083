import axios from "axios"
import { bbProcessEnv, APICallWithCache } from "../../helpers"
import { ONE_DAY_IN_MILLISECONDS } from "../../constants"
import { ZipcodeSearchResponse } from "../../types"

export default {
  get: async () => {
    try {
      const response = await axios.get(
        `${bbProcessEnv.API_URL}/api/v1/location`
      )
      return response.data
    } catch (error) {
      console.log(error)
    }
  },
  coordsToZip: async ({ lat, lng }: { lat: number; lng: number }) => {
    try {
      const response = await axios.get(
        `${bbProcessEnv.API_URL}/api/v1/coords-to-zip?lat=${lat}&lng=${lng}`
      )
      return response.data
    } catch (error) {
      console.log(error)
    }
  },
  zipcodeSearch: async (
    input: string
  ): Promise<ZipcodeSearchResponse | undefined> => {
    try {
      const response = await APICallWithCache<ZipcodeSearchResponse>({
        url: `${bbProcessEnv.API_URL}/api/v1/bb-zipcode-search`,
        params: {
          input
        },
        cacheEnabled: true,
        // five days in milliseconds
        expires: 5 * ONE_DAY_IN_MILLISECONDS
      })
      return response
    } catch (error) {
      console.log(error)
      return undefined
    }
  }
}
